import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PIDRequest, Result } from 'src/app/document/cps-pill-identifier-tool/PIDResult.interface';
import { TocItem } from 'src/app/document/cps-table-of-contents/cps-table-of-contents.component';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { userProfile } from 'src/app/utility/Objects/userProfile';
import { AuthService } from './auth-services/auth.service';

declare var datalayerService: any;

@Injectable()
export class DatalayerService {

    private page_referrer: string;
    private lastLogEvent: string;
    private _documentIds = [
        'pillid', 
        'lexi-interact', 
        'renalfunction', 
        'bodysurface',
        'hcalerts',
        'hcreporting', 
        'abbrevs', 
        'microorganisms',
        'poisoncontrol',
        'manufacturers',
        'healthorgs',
        'publicdrugprograms'
    ];
    public userProfile: userProfile;
    private isModal: boolean;

    constructor(public translationService: TranslateService, private authService: AuthService) {
        datalayerService = this;
        this.userProfile = new userProfile();
        this.loadUserProfileFromStore();
    }
    getModal(): boolean{
        return this.isModal;
    }
    setModal(value: boolean): void {
        this.isModal = value;
    }

    private stopAnalytics(): boolean {

        if (sessionStorage.getItem('stopAnalytics') === 'true') {
            return true;
        }

        return false;
    }

    pageLoadEvent(pageType: string, pageTitle: string, document?: DocumentItem,): void {
        if (this.stopAnalytics()) {
            return;
        }

        let sessionStorageSavedDataLayer;
        if (document && document.type !== 'RESOURCE' && document.type !== 'TOOL' && document.type !== 'DIRECTORY') {
            sessionStorageSavedDataLayer = {
                'language': this.translationService.currentLang.toLowerCase(),
                'document_type': document ? document.type.toLowerCase() : '',
                'document_name': document ? document.name : '',
                'document_id': document ? document.id : '',
                'document_lang': document ? document.lang.toLowerCase() : '',
                'manufacturer': document && document.manufacturerName ? document.manufacturerName : '',
                'manufacturer_id': document && document.manufacturerId ? document.manufacturerId : '',
                'page_type': pageType.toLowerCase(),
                'page_title': pageTitle,
                'page_referrer': this.page_referrer
            };
            let dataLayerToSend = sessionStorageSavedDataLayer;
            dataLayerToSend.event = 'trackEventSPA-PageView';
            dataLayerToSend.event_name = 'page_view';

            (<any>window).dataLayer.push(dataLayerToSend);
            
        } else {
            sessionStorageSavedDataLayer = {
                'event': 'trackEventSPA-PageView',
                'event_name': 'page_view',
                'language': this.translationService.currentLang.toLowerCase(),
                'page_type': pageType.toLowerCase(),
                'page_title': pageTitle,
                'page_referrer': this.page_referrer,
                'billing_id': this.userProfile?.billingid || '',
                'subscriber_type': this.userProfile?.membertype || '',
                'subscriber_id': this.userProfile?.subscriberId || '',
                'profession_type': this.userProfile?.profession || '',
                'industry': this.userProfile?.industry || '',
                'package_code': this.userProfile?.active_Subscription || '',
            };

            if (document &&
                (document.type === 'TOOL' || document.type === 'RESOURCE' || document.type === 'DIRECTORY') &&
                this._documentIds.find(id => id.toLowerCase() === document.id.toLowerCase())) {

                sessionStorageSavedDataLayer['document_type'] = document.type.toLowerCase();
                sessionStorageSavedDataLayer['document_name'] = document.name;
                sessionStorageSavedDataLayer['document_id'] = document.id;
                sessionStorageSavedDataLayer['document_lang'] = document.lang.toLowerCase();
                sessionStorageSavedDataLayer['manufacturer'] = document.manufacturerName || '';
                sessionStorageSavedDataLayer['manufacturer_id'] = document.manufacturerId || '';
            }
            (<any>window).dataLayer.push(sessionStorageSavedDataLayer);
            sessionStorage.setItem('savedDatalayer', JSON.stringify(sessionStorageSavedDataLayer));
        }
    }
    
    addBookmarkEvent(): void {
        (<any>window).dataLayer.push({
            'event': 'trackEvent-bookmarkAdded',
            'event_name': 'bookmark_added'
        });
    }

    removeBookmarkEvent(): void {
        (<any>window).dataLayer.push({
            'event': 'trackEvent-bookmarkRemoved',
            'event_name': 'bookmark_removed'
        });
    }

    globalNavCategoryClickedEvent(category: string, subcategory: string): void {
        (window as any).dataLayer.push({
            event: 'trackEvent-navigationHeader',
            event_name: 'navigation_header_click',
            category,
            subcategory
        });
    }

    globalNavSubcategoryClickedEvent(category: string, subcategory: string): void {
        (window as any).dataLayer.push({
            event: 'trackEvent-navigationHeader',
            event_name: 'navigation_header_click',
            category,
            subcategory
        });
    }

    footerNavClickedEvent(category): void {
        (window as any).dataLayer.push({
            event: 'trackEvent-navigationFooter',
            event_name: 'navigation_footer_click',
            footer_category: category
        });
    }

    languageChangedEvent(language): void {
        (window as any).dataLayer.push({
            event: 'trackEvent-navigationLanguageSwitch',
            event_name: 'language_switch',
            language_changed: language
        });
    }

    printPageEvent(document: DocumentItem, docHTML: HTMLElement): void {

        const manufacturer = docHTML ? docHTML.querySelector('span.manufacturer') : '';
        const tocSelection = docHTML ? docHTML.querySelector('a.viewedSection') : '';

        (<any>window).dataLayer.push({
            'event': 'trackEvent-printWidgetClick',
            'event_name': 'print_widget_click',
            'table_of_contents_title': tocSelection ? tocSelection.textContent : '',
            'document_type': document ? document.type.toLowerCase() : '',
            'document_name': document ? document.name.toLowerCase() : '',
            'document_id': document ? document.id : '',
            'document_lang': document ? document.lang : '',
            'manufacturer': manufacturer? manufacturer.textContent : '',
            'manufacturer_id': manufacturer? manufacturer.getAttribute('id') : '',
        });
    }

    uniquePrintPageEvent(document: DocumentItem, docHTML: Element, docFiltered: boolean): void {

        const manufacturer = docHTML ? docHTML.querySelector('span.manufacturer') : '';

        (<any>window).dataLayer.push({ 
            'event': 'trackEvent-pagePrint', 
            'event_name': 'print', 
            'doc_filtered': docFiltered, 
            'document_type':  document ? document.type.toLowerCase() : '', 
            'document_name':  document ? document.name.toLowerCase() : '', 
            'document_id':  document ? document.id : '', 
            'document_lang':  document ? document.lang : '', 
            'manufacturer': manufacturer? manufacturer.textContent : '',
            'manufacturer_id': manufacturer? manufacturer.getAttribute('id') : '',
            });           
    }

    uniquePrintPageFilterClickEvent(document: DocumentItem, docHTML: Element, docFiltered: boolean): void {

        const manufacturer = docHTML ? docHTML.querySelector('span.manufacturer') : '';

        (<any>window).dataLayer.push({ 
            'event': 'trackEvent-printFilterClick', 
            'event_name': 'initial_filter_click', 
            'doc_filtered': docFiltered, 
            'document_type':  document ? document.type.toLowerCase() : '', 
            'document_name':  document ? document.name.toLowerCase() : '', 
            'document_id':  document ? document.id : '', 
            'document_lang':  document ? document.lang : '', 
            'manufacturer': manufacturer? manufacturer.textContent : '',
            'manufacturer_id': manufacturer? manufacturer.getAttribute('id') : '',
            });           
    }

    searchResultsClickEvent(link_url: string, searchCategory: string): void {
        (<any>window).dataLayer.push({ 
            'event': 'trackEvent-searchResultsClick',
            'event_name': 'search_results_click',
            'search_result_category': searchCategory,
            'link_url': link_url
        });
    }

    searchTermEvent(search_term: string, search_type: string, num_monograph_results: number,
                    num_conditions_results: number, num_patient_info_results: number): void {
        (<any>window).dataLayer.push({
            'event': 'trackEvent-search',
            'event_name': 'search',
            'search_term': search_term,
            'search_type': search_type,
            'num_monograph_results': num_monograph_results,
            'num_conditions_results': num_conditions_results,
            'num_patient_info_results': num_patient_info_results
        });
    }

    tableOfContentsClickEvent(tocItem: TocItem, docItem: DocumentItem, docHTML: Element): void {
        const manufacturer = docHTML ? docHTML.querySelector('span.manufacturer') : '';

        (<any>window).dataLayer.push({
            event: 'trackEvent-tableOfContents',
            event_name: 'table_of_contents_click',
            table_of_contents_title: tocItem.text,
            document_type: docItem.type,
            document_name: docItem.name,
            document_id: docItem.id,
            document_lang: docItem.lang,
            document_topic: '',
            manufacturer: manufacturer ? manufacturer.textContent : '',
            manufacturer_id: manufacturer ? manufacturer.getAttribute('id') : ''
        });
    }

    onPageSearchEvent(searchKeyword: string): void {
        (<any>window).dataLayer.push({ 
            'event': 'trackEvent-onPageSearch',
            'event_name': 'on_page_search',
            'on_page_search_term': searchKeyword
        });
    }

    pidShowMoreEvent(): void {
        (<any>window).dataLayer.push({
            'event': 'trackEvent-productIdentificationShowMore',
            'event_name': 'product_identification_show_more'
        });
    }

    productIdentificationDetailsEvent(pidRequestData: PIDRequest,  pidItem: Result): void {
        (<any>window).dataLayer.push({
            'event': 'trackEvent-productIdentificationDetails', 
            'event_name': 'product_identification_details', 
            'search_by_brand': pidRequestData.brandValue, 
            'search_by_imprint': pidRequestData.imprintValue, 
            'search_by_dosage_form': pidRequestData.dosageForm, 
            'search_by_shape': pidRequestData.shape, 
            'search_by_colour': pidRequestData.colours, 
            'search_by_scored': pidRequestData.score, 
            'details_din': pidItem.din, 
            'document_name': pidItem.brandName, 
            'document_id': pidItem.parentId, 
            'manufacturer': pidItem.manufacturer, 
            'manufacturer_id': pidItem.manufacturerId 
        });
    }
    
    clininfoToolsFilterChangedEvent(filter: string, isAddingFilter: boolean): void {
        (window as any).dataLayer.push({
            event: 'trackEvent-drugAdminFoodFilter',
            event_name: 'drug_admin_food_content',
            drug_admin_food_filter: filter,
            drug_admin_food_action: isAddingFilter ? 'add' : 'remove'
        });
    }

    toolInteractionEvent(toolName: string): void {
        (window as any).dataLayer.push({
            event: 'trackEvent-toolInteractionInitiated',
            event_name: 'tool_interaction_initiated',
            tool_used : toolName
        });
    }

    documentNotFoundEvent(document: DocumentItem): void {
        (window as any).dataLayer.push({
            event: 'trackEvent-documentNotFound',
            event_name: 'document_not_found',
            document_type: document.type,
            document_name: document.id,
            document_id: document.id,
            document_lang: document.lang,
        });
    }

    loginSuccessEvent(loginStatusCode: number, userProf: userProfile): void {
        this.lastLogEvent = "login";
        (<any>window).dataLayer.push({
            'event': 'trackEvent-loginSuccess',
            'event_name': 'login',
            'login_status':  this.getLoginMessage(loginStatusCode),
            'billing_id': userProf && userProf.billingid ? userProf.billingid : '',
            'subscriber_id': userProf && userProf.subscriberId ? userProf.subscriberId : '',
            'subscriber_type': userProf && userProf.membertype ? userProf.membertype : '',
            'profession_type': userProf && userProf.profession ? userProf.profession : '',
            'industry': userProf && userProf.industry ? userProf.industry : '',
            'package_code': userProf && userProf.active_Subscription ? userProf.active_Subscription : ''
        });
    }

    loginFailureEvent(loginErrorCode: number): void {
        this.lastLogEvent = "login_failure";
        (<any>window).dataLayer.push({
            'event': 'trackEvent-loginFailure', 
            'event_name': 'login_failure', 
            'login_status': this.getLoginMessage(loginErrorCode)
        });
    }

    logoutEvent(): void {
        this.lastLogEvent = "logout";
        (<any>window).dataLayer.push({
            'event': 'trackEvent-logout',
            'event_name': 'logout'
        });
    }


    userEvent(obj: any): void {
        (<any>window).dataLayer.push(obj);
    }

    getLoginMessage(loginCode: number): string {
        switch(loginCode){
            case 0:
                return 'Successful login';
            case 100:
                return 'Successful login by IP';
            case 200:
                return 'Successful Login Refresh';
            case 1:
                return 'The log in failed. Please try again using a valid user';
            case 2:
                return 'Your subscription has expired. Contact Customer Service';
        }
    }

    bannerImpressionsEvent(bannerName: string, position: string): void {
        if (this.stopAnalytics()) {
            return;
        }

        (<any>window).dataLayer.push({ 
            'event': 'trackEvent-bannerImpressions', 
            'event_name': 'banner_impression', 
            'banner_name': bannerName, 
            'position': position
        });
                           
    }

    bannerClickEvent(bannerName: string, position: string, banner_url: string): void {
        (<any>window).dataLayer.push({ 
            'event': 'trackEvent-bannerClick', 
            'event_name': 'banner_click', 
            'banner_name': bannerName, 
            'position': position, 
            'banner_url': banner_url 
       });
    }

    bannerCloseEvent(bannerName: string, position: string): void {
        (<any>window).dataLayer.push({ 
            'event': 'trackEvent-bannerClose', 
            'event_name': 'banner_close', 
            'banner_name': bannerName,
            'position': position 
        });
    }

    fileDownloadEvent(fileExtension: string, fileName: string, position: number): void {
        (<any>window).dataLayer.push({
            'event': 'trackEvent-fileDownload',
            'event_name': 'fileDownload',
            'file_extension': fileExtension,
            'file_name': fileName,
            position: position
        });
    }

    homePageClickEvent(category: string, subcategory: string): void {
        (window as any).dataLayer.push({
            event: 'home_page_click',
            event_name: 'home_page_click',
            category,
            subcategory});
    }
    
    manufacturerInfoClick(manufacturer: string, manufacturerId: string, linkUrl: string, linkText: string, click_type: string): void {
        var checkClickType = click_type;
        if (this.getModal()) {
            checkClickType = "Modal";
        }
        (<any>window).dataLayer.push({
            'event': 'trackEvent-manufacturer_info_click',
            'event_name': 'manufacturer_info_click',
            'manufacturer': manufacturer,
            'manufacturer_id': manufacturerId,
            'link_url': linkUrl,
            'link_text': linkText,
            'click_type': checkClickType
        });
    }

    manufacturerInfoClickFeature(manufacturer: string, manufacturerId: string, contactManufacturer: string, linkText: string, click_type: string): void {
        (<any>window).dataLayer.push({
                'event': 'trackEvent-manufacturer_info_click',
                'event_name': 'manufacturer_info_click',
                'manufacturer': manufacturer,
                'manufacturer_id': manufacturerId,
                'link_url': contactManufacturer,
                'link_text': linkText,
                'click_type': click_type
        });
    }

    home_page_click(category: string, subcategory: string): void {
        (window as any).dataLayer.push({
            event: 'trackEvent-homePageClick',
            event_name: 'home_page_click',
            category,
            subcategory
        });
    }

    manufacturer_info_click(manufacturer: string, manufacturer_id: string, linkUrl: string, clickType: string): void {
        (window as any).dataLayer.push({
            event: 'trackEvent-manufacturer_info_click',
            event_name: 'manufacturer_info_click',
            manufacturer: manufacturer,
            manufacuter_id: manufacturer_id,
            link_url: linkUrl,
            link_text: linkUrl,
            click_type: clickType
        });
    }

    internalLinkClick(linkText: string): void {
        (window as any).dataLayer.push({
            event: 'internal_link_click',
            event_name: 'internal_link_click',
            internal_link_text: linkText
        });
    }

    setPageReferrer(referrer: string): void {
        this.page_referrer = referrer;
    }

    getPageReferrer(): string {
        return this.page_referrer;
    }

    getLastLogEvent(): string {
        return this.lastLogEvent;
    }

    loadUserProfileFromStore(): void {
        const storedUserInfo = this.authService.getUserProfileDetails();
        storedUserInfo.subscribe(userInfo => {
            if (!!userInfo && !!userInfo.userProfile) {
                this.userProfile = userInfo.userProfile;
            }
        });
    }

}

<div *ngIf="data && isMessageCenterDisplayed(data)">    
    <div class="messagesContainer" [ngClass]="{'collapsed': messagePanelCollapsed}">
        <div class="row align-items-start">
            <div class="col-12 messageContainerHeading">
                <span class="alertIcon"><img src="assets/images/alert-icon.png"></span>
                <span class="impMsgLabel">{{'MessageCenter.messageLabel' | translate}}</span>
                <button class="collapseIcon" (click)="toggleMessagePanel()"></button>
            </div>
        </div>
        <div class="row msgBlock">
            <ng-template [ngIf]="!messagePanelCollapsed">

                <app-cps-advisories-panel [advisories]="data.messages.hcalerts" class="col-xl col-lg col-md col-sm-12 col-12 messageBorder"
                    *ngIf="!!data.messages.hcalerts && data.messages.hcalerts.length>0"></app-cps-advisories-panel>
                <!-- <div *ngIf="separatorHCA" class="separator"></div> -->

                <app-cps-shortages-panel [drugShortages]="data.messages.shortages" class="col-xl col-lg col-md col-sm-12 col-12 messageBorder"
                    *ngIf="!!data.messages.shortages"></app-cps-shortages-panel>
                <!-- <div *ngIf="separatorShortage" class="separator"></div> -->

                <app-cps-important-messages [importantMsgs]="data.messages.important" class="col-xl col-lg col-md col-sm-12 col-12 messageBorder"
                    *ngIf="!!data.messages.important && data.messages.important.length>0"></app-cps-important-messages>
                <!-- <div *ngIf="separatorImpMsg" class="separator"></div> -->
                
                <app-cps-notifications-panel [cpsn]="data.messages.cpsn" class="col-xl col-lg col-md col-sm-12 col-12 messageBorder"
                    *ngIf="data.messages.cpsn==='y'"></app-cps-notifications-panel>                    
            </ng-template>
        </div>
    </div>    
</div>
<div *ngIf="contactManufacturer">
    <app-cps-contact-manufacturer [contactManufacturer]="data.messages.manufacturer" [currentDocument] = "currentDocument"
        *ngIf="!!data.messages.manufacturer"></app-cps-contact-manufacturer>
</div>
export default class AlphaBarUtils {
    static getScrollOffsetForWindowWidth() {
        if (window.innerWidth < 400) {
            return 270;
        } else if (window.innerWidth >= 400 && window.innerWidth <= 500) {
            return 360;
        } else if (window.innerWidth > 500 && window.innerWidth <= 768) {
            return 320;
        } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
            return 300;
        } else {
            return 260;
        }
    }

    // on the glossary pages the scroll is quite finicky with the alphabar
    // these offsets help get the actual position we need to get to
    static getAdjustedOffsetForGlossaryPages(offset: number) {
        if (offset < 100) {
            offset -= 40;
        } else if (offset >= 100 && offset < 140) {
            offset -= 80;
        } else {
            offset -= 100;
        }

        return offset;
    }
}